$shadow: var(--main-shadow);

.img,
.avatar {
  position: absolute;
  display: block;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.75em;
  background: #60dabf;
  text-shadow: $shadow;
}
