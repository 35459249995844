$max-width: 933px;
$label-text: var(--text-light-green);
$btn-background: var(--bg-blue);
$shadow: var(--main-shadow);
$error-color: var(--error-color);

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.content {
  width: 75%;
  max-width: $max-width;
  margin: 0 auto;
  padding-top: 5%;
  padding-bottom: 10%;
  margin-bottom: 2%;
  box-shadow: $shadow;

  .logo {
    max-width: 40%;
  }
}
