.label {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.8em;
}

.selectContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.cardSelect {
  width: 40%;
  margin-left: 5px;
}

@media (max-width: 520px) {
  .cardSelect {
    width: 50%;
  }
  .selectContainer {
    justify-content: center;
  }
}
