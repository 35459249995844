$page-width: var(--page-width);
$guidelines-width: var(--guidelines);
$bg-highlight: var(--bg-light-gray);

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.cardItem {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-bottom: 2%;
}

.customPlayerCard {
  font-size: 16px;
  width: initial;
}

.author {
  width: 40%;
  font-size: 11px;
}

.cardKickUser {
  text-align: center;
  color: red;
  min-width: 30%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0.5% 1%;
  font-style: var(--second-font);
  margin-bottom: 2%;
  font-size: 12px;
}

.message {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: start;
  padding: 2% 1% 1% 3%;
  border-radius: 20px 0px 0px 20px;
  width: 60%;
  font-size: 12px;
  word-break: break-word;
}

.messageForm {
  padding-top: 10px;
}

.wrapperMessage {
  display: flex;
  align-items: baseline;
}

.textarea {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2% 1% 1% 3%;
  border-radius: 20px 20px 0px 20px;
  font-size: 12px;
}

.iconMessage {
  width: 6%;
}
.cardItem {
  display: flex;
}
.messagePrompt {
  position: absolute;
  padding-top: 5px;
  color: rgb(128, 128, 128);
  text-align: left;
}

@media (max-width: 860px) {
  .author,
  .message {
    width: 100%;
  }
}
