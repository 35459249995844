$shadow: var(--main-shadow);
$font: var(--second-font);

.timer {
  display: flex;
  flex-direction: column;
  width: 200px;
  border-radius: 4px;
  box-shadow: $shadow;
  font-family: $font;
  font-weight: 700;
}

.legend {
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
}

.time {
  display: flex;
  justify-content: space-around;
}

.colon {
  align-self: center;
  font-size: 24px;
  line-height: 30px;
}

.input {
  width: 74px;
  height: 60px;
  background: transparent;
  border: none;
  border-radius: 6px;
  color: #000000;
  font-size: 64px;
  text-align: right;
  -moz-appearance: textfield;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:focus {
  box-shadow: inset $shadow;
  outline: none;
}
