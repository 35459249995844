$z-index: var(--max-z-index);

.loadingAnimation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 200px;
  z-index: $z-index;
  margin: auto;
  pointer-events: none;
  animation-name: rotation;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotation {
  0% {
    transform: scale(0.25) rotate(-360deg);
    opacity: 0.5;
    filter: grayscale(0.25);
  }
  25% {
    transform: scale(0.5) rotate(-180deg);
    opacity: 0.75;
    filter: grayscale(0.5);
  }
  50% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
    filter: grayscale(1);
  }
  75% {
    transform: scale(0.5) rotate(-180deg);
    opacity: 0.75;
    filter: grayscale(50%);
  }

  100% {
    transform: scale(0.25) rotate(-360deg);
    opacity: 0.5;
    filter: grayscale(25%);
  }
}
