.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  color: black;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.popupTitle {
  font-size: 1.4em;
  padding-bottom: 5%;
}
.form {
  display: flex;
  flex-direction: column;
}

.label {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.8em;
}

.urlInput,
.titleInput {
  font-size: 0.9em;
  width: 70%;
  border: 1px solid #2b3a67;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}

.prioritySelect {
  width: 40%;
}

.warning {
  color: red;
  font-size: 0.6em;
  position: absolute;
  top: 95%;
  left: 10%;
}

@media (max-width: 765px) {
  .container {
    width: 100%;
    font-size: 18px;
  }
}

@media (max-width: 520px) {
  .container {
    text-align: center;
  }
  .prioritySelect {
    width: 50%;
  }
}
