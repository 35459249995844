$main-font: var(--main-font);
$second-font: var(--second-font);
$bg-border: var(--border-button-blue);
$bg: var(--bg-blue);
$error-color: var(--error-color);

.connect {
  display: flex;
  max-width: 876px;
}

.title {
  font: normal bold 64px/75px $main-font;
  color: #000000;
}

.rowTitle {
  margin-bottom: 50px;
}

.observerLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-top: 15px;
  font: normal bold 24px/30px $second-font;
  color: #000000;
}

.observer {
  position: relative;
  right: 50px;
  display: flex;
  justify-content: flex-start;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  word-break: normal;
}

.label {
  display: flex;
  align-items: center;
  font: normal bold 24px/28px $main-font;
  text-align: start;
  color: #000000;
}

.placeholder {
  display: flex;
  align-items: center;
  font: normal 300 20px/28px $main-font;
  text-align: center;
  color: #000000;
}

.left,
.right {
  text-align: start;
}

.confirm {
  width: 189px;
  height: 60px;
  background: $bg;
  border: 1px solid $bg-border;
  border-radius: 3px;
  font: normal bold 24px/48px $second-font;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.cancel {
  width: 189px;
  height: 60px;
  font: normal bold 24px/48px $second-font;
  text-align: center;
  color: $bg;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  border: 1px solid $bg-border;
  border-radius: 3px;
}

.tipError {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 3%;
  font-style: normal;
  font-size: 16px;
  text-align: start;
  color: $error-color;
  white-space: nowrap;
}

@media (max-width: 1000px) {
  .title {
    font-size: 40px;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: 24px;
  }
  .label {
    font-size: 18px;
    font-weight: 500;
  }
}
