$page-width: var(--page-width);
$guidelines-width: var(--guidelines);
$bg-highlight: var(--bg-light-gray);
$sidebar-width: var(--sidebar-width);
$shadow: var(--main-shadow);
$z-index: var(--sidebar-z-index);

.container {
  flex-shrink: 0;
  z-index: $z-index;
  width: $sidebar-width;
  display: flex;
  padding: 40px 17px;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: $shadow;
}

.messageHighlight {
  background: $bg-highlight;
}

.cardItem {
  display: flex;
  flex-wrap: wrap-reverse;
}

@media (max-width: 860px) {
  .container {
    width: calc(#{$sidebar-width} / 2);
  }
}

@media (max-width: 499px) {
  .container {
    position: fixed;
    align-items: center;
    right: -100%;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    background: #ffffff;
  }
}
