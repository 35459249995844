@import '~bootstrap/scss/bootstrap.scss';

:root {
  --main-font: 'Roboto', sans-serif;
  --second-font: 'Ruda', sans-serif;
  --bg-light-gray: #eee;
  --bg-light-green: rgba(96, 218, 191, 0.46);
  --bg-gray: rgba(0, 0, 0, 0.43);
  --bg-blue: rgb(43, 58, 103);
  --border-button-blue: rgb(73, 106, 129);
  --text-light-green: rgb(102, 153, 155);
  --bg-avatar: rgb(96, 218, 191);
  --main-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --second-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  --avatar-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  --overlay-z-index: 900;
  --popup-z-index: 910;
  --guidelines: 1440px;
  --page-width: 1000px;
  --page-width-sidebar: 976px;
  --sidebar-z-index: 20;
  --bg-overlay: rgba(196, 196, 196, 0.6);
  --error-color: #db1b1b;
  --max-z-index: 999;
  --button-z-index: 10;
  --sidebar-width: 468px;
}

body {
  font-family: var(--main-font);
}
