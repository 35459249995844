$btn-background: var(--bg-blue);
$font: var(--second-font);
$page-width: var(--page-width);
$page-width-sidebar: var(--page-width-sidebar);
$button-width: 189px;
$shadow: var(--main-shadow);
.container {
  display: flex;
  justify-content: center;
  height: 100%;
}
.content {
  width: 70%;
  max-width: $page-width;
  padding: 2% 3% 0;
  box-shadow: $shadow;
}
.contentWithSidebar {
  max-width: $page-width-sidebar;
}
.titleSprint {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 2% 0;
}
.iconIssue {
  display: block;
  width: 1em;
  margin-left: 2%;
  cursor: pointer;
}

.titleSettings {
  color: black;
  margin: 2% 0;
  font: normal 700 22px/30px $font;
}

.input {
  display: inline;
  width: 55%;
  box-shadow: $shadow;
}

.btnCopy {
  background: $btn-background;
  width: $button-width;
  font-weight: 500;
}

.btnGameContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 8px;
  margin: 3% auto;
}

.btnStart,
.btnCancel {
  width: $button-width;
  font-weight: 500;
}

.btnStart {
  background: $btn-background;
}
.btnCancel {
  border: 1px solid $btn-background;
  text-shadow: $shadow;
}

.containerSettings {
  padding: 2% 0;
}

@media (max-width: 1000px) {
  .content {
    width: 90%;
  }
}

@media (max-width: 946px) {
  .input {
    font-size: 16px;
  }
  .titleLinkTo,
  .titleSettings {
    font-size: 17px;
  }
}

@media (max-width: 820px) {
  .btnGameContainer {
    flex-direction: column;
  }
}

@media (max-width: 640px) {
  .content {
    padding: 0 10px 31px 10px;
  }
}
@media (max-width: 530px) {
  .containerLinkToLobby,
  .settingsList {
    width: 80%;
    text-align: start;
    margin: 0 auto;
  }

  .btnGameContainer {
    width: 80%;
  }
}

@media (max-width: 499px) {
  .contentWithSidebar {
    display: none;
  }
}
