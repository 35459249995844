$font: var(--second-font);
$shadow: var(--main-shadow);

.titleIssues {
  color: #000000;
  margin: 2% 0;
  font: normal 700 22px/30px $font;
}

.wrapperIssue {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 17px;
}

.card {
  width: 300px;
}

.card,
.createIssueCardContainer {
  height: 5.5rem;
}

@media (max-width: 946px) {
  .titleIssues {
    font-size: 17px;
  }
}

@media (max-width: 820px) {
  .card {
    width: 200px;
  }
}

@media (max-width: 530px) {
  .wrapperIssue {
    width: 80%;
    text-align: start;
    margin: 0 auto;
  }
}
