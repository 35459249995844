$font: var(--second-font);

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.role {
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.6);
  font-family: $font;
  font-weight: 700;
  font-size: 12px;
}
