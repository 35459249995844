.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  color: black;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 700;
  position: relative;
}

.popupTitle {
  font-size: 1.4em;
  padding-bottom: 5%;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-size: 0.8em;
}

.iconClose {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 4%;
  right: 2%;
  cursor: pointer;
  &:hover {
    transform: scale(1.5);
  }
}

.btnDelete {
  background: red !important;
  color: #ffffff !important;
  &:hover {
    color: initial !important;
  }
}

@media (max-width: 765px) {
  .container {
    width: 100%;
    font-size: 18px;
  }
}

@media (max-width: 520px) {
  .container {
    text-align: center;
  }
}
