$second-font: var(--second-font);

.playCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 130px;
  height: 211px;
  font: normal bold 1rem $second-font;
  text-align: center;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
}

.iconEdit {
  position: absolute;
  top: 1.5%;
  right: 1%;
  width: 20px;
}
.iconEdit:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.top,
.bottom {
  position: absolute;
  width: 16%;
  font-size: 1.5em;
}

.center {
  font-size: 3em;
}

.top {
  top: 0;
  left: 5px;
}

.bottom {
  bottom: 0;
  right: 5px;
  transform: rotate(180deg);
}

.chosenCardBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background: url('/shared/assets/icons/chosen.svg') no-repeat center,
    rgba(10, 207, 131, 0.5);
  background-size: 20% 20%;
  border-radius: 10px;
}

.btnAddCard {
  color: gray;
  font-size: 48px;
  font-weight: 700;
  border: 4px solid gray;
  border-radius: 50%;
  width: 50%;
  margin: 0 auto;
}

.btnAddCard:hover {
  cursor: pointer;
  transform: scale(1.1);
}
