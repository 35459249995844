$bg-blue: var(--bg-blue);

.infoPopup {
  min-width: 100%;
  color: gray;
  padding: 1%;
  font-size: 0.88rem;
  .infoValue {
    word-break: break-all;
  }
  .nameInfo {
    font-weight: 600;
    color: $bg-blue;
  }
}
