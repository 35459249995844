$shadow: var(--second-shadow);

.score {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  margin-left: 25px;
  text-align: center;
  color: #000000;
  border: none;
  background: transparent;
}

.score:focus {
  box-shadow: inset $shadow;
  outline: none;
}
