$label-text: var(--text-light-green);

.form {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding-left: 2%;
  text-align: start;
  .buttonNewGameWrap {
    padding-left: 55%;
  }
  .connection {
    position: relative;
  }

  .btn {
    width: 200px;
    margin-top: -0.6%;
    font-size: 22px;
  }

  .btnConnect {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .input {
    display: inline;
    width: 55%;
    border-radius: 0;
    border-bottom-left-radius: 5px;
  }

  .labelNewGame,
  .labelConnect {
    width: 100%;
    font-weight: bold;
    font-size: 2.3em;
    color: $label-text;
  }

  .labelConnect {
    margin-top: 3%;
    text-align: center;
  }
}

@media (max-width: 1030px) {
  .form {
    .labelNewGame,
    .labelConnect {
      font-size: 2em;
    }
    .input {
      width: 200px;
    }
    .buttonNewGameWrap {
      padding-left: 200px;
    }
  }
}

@media (max-width: 946px) {
  .form {
    .labelNewGame,
    .labelConnect {
      font-size: 1.9em;
    }
  }
}

@media (max-width: 918px) {
  .form {
    .buttonNewGameWrap {
      padding-left: 0;
    }
    .input {
      border-radius: 0;
    }
    .btnConnect {
      border-bottom-left-radius: 5px;
      border-top-right-radius: 0;
    }
  }
}

@media (max-width: 768px) {
  .form {
    .labelNewGame,
    .labelConnect {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 695px) {
  .form {
    .labelNewGame,
    .labelConnect {
      font-size: 1.3em;
    }
  }
}

@media (max-width: 576px) {
  .form {
    .labelNewGame,
    .labelConnect {
      font-size: 1.1em;
    }
  }
}

@media (max-width: 490px) {
  .form {
    width: 75%;
    .labelNewGame,
    .labelConnect {
      font-size: 1em;
    }
  }
}

@media (max-width: 410px) {
  .form {
    width: 100%;
  }
}
