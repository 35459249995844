.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
}

.transitionGroup {
  height: 100%;
}

.content {
  flex: 1;
}

.page {
  position: relative;
  display: flex;
  perspective: 1000px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}
.containerEnter {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  transition: transform 1000ms;
  backface-visibility: hidden;
}
.containerEnterActive {
  transform: rotateY(0deg);
}
.containerExit {
  transform: rotateY(180deg);
  transition: transform 1000ms;
  opacity: 1;
}
.containerExitActive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: rotateY(0);
  opacity: 0;
  transition: transform 1000ms;
}
