$main-font: var(--main-font);
$second-font: var(--second-font);
$shadow: var(--main-shadow);
$bg-blue: var(--bg-blue);

.card {
  display: flex;
  position: relative;
  align-items: center;
  width: 300px;
  padding: 10px;
  color: gray;
  box-shadow: $shadow;
  font: 24px $second-font;
  border-radius: 4px;
}
.avatarContainer {
  position: relative;
  width: 17%;
  height: 0;
  padding-top: 17%;
}

.observerImg {
  position: absolute;
  width: 20px;
  right: 3px;
  top: -2px;
}
.info {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 8px;
  text-align: start;
}
.remove {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 14%;
  padding-top: 14%;
  margin-left: auto;
  border: none;
  background: transparent;
  cursor: pointer;
}
.remove:hover {
  transform: scale(1.2);
}
.removeImg {
  position: absolute;
  top: 0;
  width: 100%;
}
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
  line-height: 1em;
  color: #000000;
}
.jobPosition {
  font-size: 0.38em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.currentUser {
  margin-bottom: 0;
  color: #000000;
  text-transform: uppercase;
  font-family: $main-font;
  font-size: 0.44em;
  font-weight: 700;
  text-align: left;
}

.popupTitle {
  font-size: 1.4em;
  padding-bottom: 5%;
  margin: 0 auto;
  color: #000000;
}

.kickPopup {
  margin: 0 auto;
}

.kickedPlayerName {
  color: #66999b;
  font-weight: 500;
}

@media (max-width: 810px) {
  .card {
    width: 200px;
    font-size: 18px;
  }
  .observerImg {
    width: 15px;
  }
}
